<template>
  <div class="order-particulars">
    <div class="header" :class="status===2||status===3?'succeed':'cancel'">
      <div class="logo">北京普惠健康保</div>
      <div class="status">
        <img src="../../assets/images/order/succeed.png" v-if="status===2||status===3">
        <img src="../../assets/images/order/cancel.png" v-else>
        <span>{{status | orderStatus}}</span>
      </div>
      <div class="countDown" v-if="status===2">
        距失效还剩<van-count-down :time="form.payUrlExpireTime" @finish="onFinish" />
      </div>
      <div class="identity-info" v-for="(item, index) in insuredSubOrders" :key="index">
        <div class="identity-info-top flex">
          <span>被保人：{{item.insuredName}}</span>
          <span>￥{{item.firstYearPremium}}</span>
        </div>
        <div class="identity-info-bottom flex">
          <span>{{item.insuredIdentityType | identityType}}：{{item.insuredIdentityCode}}</span>
          <span :class="status===2||status===3?'highlight':''">{{item.orderStatus | orderLowerStatus}}</span>
        </div>
      </div>
      <div class="money">
        <span>合计：￥{{form.total}}</span>
        <span v-if="status===2">待支付：￥{{form.total}}</span>
      </div>
    </div>
    <div class="order-message">
      <div class="order-message-title flex">
        <span class="name">订单信息</span>
        <span class="details" @click="skip(`/policy?orderId=${form.orderId}`)" v-if="status===3">查看保单 <van-icon name="arrow" /></span>
      </div>
      <div class="order-message-content">
        <div class="order-message-content-item">
          <span class="name">订单编号：</span>
          <span>{{form.orderCode}}</span>
        </div>
        <div class="order-message-content-item">
          <span class="name">创建日期：</span>
          <span>{{form.createTime}}</span>
        </div>
      </div>
    </div>
    <div class="order-message" v-if="status===2">
      <div class="order-message-title flex">
        <span class="name">活动规则</span>
      </div>
      <div class="order-message-content">
        <div class="order-message-content-item">
          <span class="name">仅限手机GPS定位在北京，且使用62开头的储蓄卡支付，云闪付APP为最新版本的用户，满足以上条件方可获得立减10元优惠。</span>
        </div>
      </div>
    </div>
    <div class="footer flex" v-if="status===2">
      <div class="hairline-btn" @click="cancelOrder">取消订单</div>
      <div class="primary-btn" @click="onPay">去支付</div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { day } from '@/utils'
import { mapGetters } from "vuex";
import { recordMixin } from '@/utils/mixin/recordMixin.js'
export default {
  name: '',
  mixins: [recordMixin],
  data () {
    return {
      status: 1,
      form: {},
      insuredSubOrders: [],
      isExpire: false,
      orderId: ''
    }
  },
  computed: {
    ...mapGetters(['endDate']),
  },
  created () {
    let { orderId } = this.$route.query
    this.orderId = orderId
    let that = this
    let expire = this.endDate + '23:59:59'
    let n = moment().format('X');
    let e = moment(expire).format('X');
    if (+n >= +e) this.isExpire = true
    else if (n + 3600 >= +e) {
      setTimeout(() => {
        that.isExpire = true
      }, e * 1000 - n * 1000)
    }
  },
  mounted () {
    this.orderDetailst()
  },
  methods: {
    skip (e) {
      this.$router.push({ path: e })
    },
    onFinish () {
      // this.form.insuredSubOrders.map(c => {
      //   c.orderStatus = 102
      // })
      // this.form.insuredSubOrders = Object.assign([], this.form.insuredSubOrders)
      // this.form.tocBusinessStatus = 5
      this.orderDetailst()
    },
    orderDetailst () {
      let params = {
        orderId: this.orderId
      }
      this.$API.orderDetails(params).then(res => {
        console.log(res.data)
        res.data.createTime = (res.data.createTime && day.format(new Date(res.data.createTime), 'YYYY-MM-DD HH:mm:ss')) || ''
        // res.data.countDown = res.data.tocBusinessStatus === 2 && res.data.payUrlExpireTime ? res.data.payUrlExpireTime - Date.now() : ''
        this.form = res.data
        console.log(this.form)
        this.insuredSubOrders = res.data.insuredSubOrders || []
        this.status = res.data.tocBusinessStatus
      }).catch(err => {
      })
    },
    cancelOrder () {
      this.$dialog({
        title: '系统提示',
        showCancelButton: true,
        message:
          '确认取消此订单吗？',
      })
        .then(() => {
          let params = {
            id: this.orderId
          }
          this.$API.cancelOrder(params).then(res => {
            this.orderDetailst()
          }).catch(err => {
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    onPay (row) {
      if (this.isExpire) return this.$toast('非常抱歉，本期投保期限已过，敬请下期开放！')
      this.onRecord('Click', '立即支付')
      let that = this
      let params = {
        orderId: this.orderId,
        paymentMethod: 3, // 付款方式 1-支付宝 2-微信 3-云闪付
        platform: 1 // 支付交易平台 1-云闪付 2-支付宝 3-微信 4-保司收银台 5-银联商务
      }
      this.$API.getPayTN(params).then(res => {
        let tn = res.data.platformBillNo
        console.log('res => ', tn)
        upsdk.pay({
          tn: tn,
          success: function () {
            console.log('支付成功')
            that.onRecord('System', '支付结果', '支付成功')
            that.orderDetailst()
            that.putRecord(that.orderId)
            that.$router.replace({ path: '/paid', query: { orderId: that.orderId } })
          },
          fail: function (err) {
            console.log('支付失败')
            that.onRecord('System', '支付结果', '支付失败')
            that.putRecord(that.orderId)
          }
        });
      }).catch(err => {
        console.log('err => ', err)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.order-particulars {
  font-family: PingFangSC-Regular, PingFang SC;
  min-height: 100vh;
  background-color: #f7f7f7;
  overflow: hidden;
  .header {
    width: 686px;
    // height: 450px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 16px;
    overflow: hidden;
    padding: 36px 32px;
    &.succeed {
      background: linear-gradient(224deg, #ff7108 0%, #feb770 100%);
    }
    &.cancel {
      background: linear-gradient(45deg, #bbc1cc, #868d97);
    }
    .logo {
      font-size: 36px;
      font-weight: 600;
      color: #ffffff;
      line-height: 36px;
    }
    .status {
      display: flex;
      align-items: center;
      margin-top: 32px;
      margin-bottom: 40px;
      img {
        width: 56px;
        height: 56px;
        margin-right: 15px;
      }
      span {
        font-size: 48px;
        color: #fff;
      }
    }
    .countDown {
      font-size: 40px;
      color: #ffffff;
      line-height: 40px;
      margin-bottom: 28px;
      display: flex;
      align-items: center;
      /deep/.van-count-down {
        font-size: 0.53333rem;
        color: #ffffff;
      }
    }
    .identity-info {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      .identity-info-top {
        font-size: 30px;
        color: #333333;
        line-height: 30px;
      }
      .identity-info-bottom {
        font-size: 24px;
        color: #666666;
        line-height: 24px;
        margin-top: 16px;
      }
    }
    .identity-info + .identity-info {
      margin-top: 16px;
    }
    .money {
      text-align: right;
      color: #fff;
      margin-top: 32px;
      font-size: 30px;
      font-family: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif";
      span + span {
        margin-left: 40px;
      }
    }
  }
  .order-message {
    width: 686px;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 24px;
    border-radius: 20px;
    padding: 0 28px;
    .order-message-title {
      padding: 38px 0 18px 0;
      border-bottom: 1px solid #e5e5e5;
      .name {
        font-size: 36px;
        color: #333;
        font-weight: 600;
      }
      .details {
        font-size: 26px;
        color: #7d8ba2;
      }
    }
    .order-message-content {
      padding-bottom: 40px;
      .order-message-content-item {
        margin-top: 30px;
        color: #666;
        font-size: 28px;
      }
      .order-message-content-item + .order-message-content-item {
        margin-top: 18px;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 60px;
    left: 0;
    right: 0;
    padding: 0 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    .hairline-btn {
      width: 328px;
      background: #ffffff;
      border-radius: 48px;
      border: 2px solid #ff0904;
      padding: 26px 0;
      color: #ff0904;
    }
    .primary-btn {
      width: 328px;
      background: #ff0904;
      border-radius: 48px;
      padding: 26px 0;
      color: #fff;
    }
  }
  .highlight {
    color: #fa6400;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
