const record = [{
	"time": 1729913517000,
	"type": "System",
	"major": "打开小程序",
	"minor": "进入投保主页"
}, {
	"time": 1729913521000,
	"type": "Click",
	"major": "点击确认告知按钮",
	"minor": ""
}, {
	"time": 1729913523000,
	"type": "Input",
	"major": "编辑投保人身份证号",
	"minor": "110105198109173319"
}, {
	"time": 1729913545000,
	"type": "Input",
	"major": "编辑投保人手机号码",
	"minor": "13671008368"
}, {
	"time": 1729913551000,
	"type": "Click",
	"major": "立即参保",
	"minor": ""
}, {
	"time": 1729913552000,
	"type": "Click",
	"major": "勾选阅读并同意协议",
	"minor": ""
}, {
	"time": 1729913554000,
	"type": "Click",
	"major": "打开查看协议",
	"minor": ""
}, {
	"time": 1729913556000,
	"type": "Refer",
	"major": "阅读投保协议",
	"minor": "投保须知"
}, {
	"time": 1729913561000,
	"type": "Click",
	"major": "协议阅读并同意",
	"minor": "投保须知"
}, {
	"time": 1729913566000,
	"type": "Refer",
	"major": "阅读投保协议",
	"minor": "重要提示"
}, {
	"time": 1729913584000,
	"type": "Click",
	"major": "协议阅读并同意",
	"minor": "重要提示"
}, {
	"time": 1729913602000,
	"type": "Click",
	"major": "协议阅读并同意",
	"minor": "其它协议"
}, {
	"time": 1729913641000,
	"type": "Refer",
	"major": "阅读投保协议",
	"minor": "投保须知"
}, {
	"time": 1729913664000,
	"type": "Click",
	"major": "添加被保人",
	"minor": ""
}, {
	"time": 1729913665000,
	"type": "Input",
	"major": "修改被保人关系",
	"minor": "父母"
}, {
	"time": 1729913665000,
	"type": "Input",
	"major": "编辑被保人姓名",
	"minor": "方月菊"
}, {
	"time": 1729913667000,
	"type": "Input",
	"major": "编辑被保人身份证号",
	"minor": "110105195411053349"
}, {
	"time": 1729913667000,
	"type": "Input",
	"major": "编辑被保人身份证号",
	"minor": "110105195411053349"
}, {
	"time": 1729913671000,
	"type": "Input",
	"major": "编辑被保人手机号码",
	"minor": "17718516760"
}, {
	"time": 1729913671000,
	"type": "Input",
	"major": "编辑被保人身份证号",
	"minor": "110105195411053349"
}, {
	"time": 1729913676000,
	"type": "Input",
	"major": "编辑被保人手机号码",
	"minor": "17718516760"
}, {
	"time": 1729913683000,
	"type": "Click",
	"major": "立即参保",
	"minor": ""
}, {
	"time": 1729913683000,
	"type": "Click",
	"major": "提交投保信息",
	"minor": ""
}]
export default record