<template>
  <div class="home-main">
    <div class="slice-pic-box">
      <img src="@/assets/images/home/slice/slice_00.jpg" />
      <div class="pic-option-box">
        <img src="@/assets/images/home/slice/slice_01.jpg" />
        <div class="text-btn" style="--top:6;--right:5" @click="openFilePopup(13)"></div>
      </div>
      <img src="@/assets/images/home/slice/slice_02.jpg" />
    </div>
    <!-- <div class="slice-pic-box">
			<div class="slice-pic-tit"><img src="@/assets/images/home/slice/tag_1.jpg" /></div>
		</div>
		<div class="insuranceCompany-list">
			<div v-for="(item,index) in InsuranceCompany" 
			 :class="['insuranceCompany-item bgd-image', 'company'+item.value, item.value==companyCode?'active':'']"
			 @click="CompanyCelect(item)">
			</div>
		</div> -->
    <van-sticky>
      <div class="tags-box">
        <div class="tags-content">
          <div :class="['tags-item font32 fontCol3 fontBold',fixedCurrent==0?'active':'']" @click="tabClick(0)">立即投保
          </div>
          <div :class="['tags-item font32 fontCol3 fontBold',fixedCurrent==1?'active':'']" @click="tabClick(1)">产品特色
          </div>
          <div :class="['tags-item font32 fontCol3 fontBold',fixedCurrent==2?'active':'']" @click="tabClick(2)">理赔案例
          </div>
          <div :class="['tags-item font32 fontCol3 fontBold',fixedCurrent==3?'active':'']" @click="tabClick(3)">常见问题
          </div>
          <div class="tags-line" :style="{'--ind': fixedCurrent}"></div>
        </div>
      </div>
    </van-sticky>
    <div id="tab0" ref="tab0"></div>
    <div class="form-box" ref="proposer">
      <div class="form-card">
        <div class="form-tit">
          <!-- <div class="serial-nub">1</div> -->
          <div class="tit">投保人信息<span>(购买人本人)</span></div>
        </div>
        <div class="form-content">
          <div class="insureds-item">
            <insureForm ref="insureFormP" v-model="proposer" :companyCode="companyCode"
              @exRecord="(type,major,minor) => {onRecord(type,major,minor)}"></insureForm>
          </div>
          <div class="verifyMasking" v-if="authorizationType != 2" @click="postAuthorization"></div>
        </div>
      </div>
    </div>
    <div class="user-agreement-box agreement-box">
      <div class="check-btn"><van-checkbox v-model="isAgree" checked-color="#ed0500" icon-size="16px" @click="onCheck"></van-checkbox></div>
      <div class="agreement-list font24 fontCol9">我已阅读并同意
        <span @click="openPopup">
          <span v-for="item in agreementFileCode.filter(it => it.value>2 && it.value<100)" :key="item.value">《{{item.text}}》</span>
        </span>
      </div>
      <!-- <van-checkbox v-model="isUserRead" checked-color="#ed0500" icon-size="16px">
        <span>我已阅读并确认</span>
        <span class="agreement-item" @click.stop="openFilePopup(16)">《隐私政策》</span>
        <span class="agreement-item" @click.stop="openFilePopup(17)">《用户协议》</span>
        <span class="agreement-item" @click.stop="openFilePopup(121)">《客户告知书》</span>
      </van-checkbox> -->
    </div>
    <div class="form-box paddingB40 marginT52" ref="insureds">
      <div class="form-card">
        <div class="form-tit">
          <!-- <div class="serial-nub">2</div> -->
          <div class="tit">被保人信息<span>(为谁买)</span></div>
        </div>
        <div class="form-content" :key="onKey">
          <div class="slice-pic-box">
            <img src="@/assets/images/home/slice/slice_notice.jpg" />
          </div>
          <template v-for="(item,index) in insureds">
            <div class="insureds-item inset-shadow" :key="index" :ref="'insured'+index">
              <div class="mark-box bgd-image">
                <div class="mark-icon bgd-image"></div>
                <div class="mark-text">被保人{{index+1}}</div>
              </div>
              <div class="del-btn" @click="delInsureds(index)"><img src="@/assets/images/home/del.png" /></div>
              <insureForm ref="insureFormI" v-model="insureds[index]" :disabled="item.maskData ? true : false"
                :insureds="insureds" @validIdCard="onValidIdCard" formType="insureds" :proposer="proposer"
                :companyCode="companyCode" :relationList="relationList" @openFilePopup="openFilePopup"
                @exRecord="(type,major,minor) => {onRecord(type,major,minor)}"></insureForm>
            </div>
          </template>
        </div>
        <div class="add-btn font32" @click="addInsureds">添加被保险人</div>
      </div>
    </div>
    <div class="notice-box">
      <div class="notice-tit-box">
        <div class="notice-tit font24 fontCol6">可以给父母、配偶、子女等(符合参保人群)的直系亲属投保</div>
        <!-- <div class="notice-icon bgd-image"></div>
				<div class="notice-tit font28 fontCol6">基本医疗/中央公费/新市民情况说明</div>
				<div class="notice-option font24" @click="notice=!notice">
					{{notice?'收起':'展开'}}
					<van-icon :name="notice?'arrow-up':'arrow-down'" color="#ed0500"/>
				</div> -->
      </div>
      <div :class="['notice-content',notice?'active':'']">
        <div class="text-flex">
          <div class="num font24 fontCol6">1、</div>
          <div class="text font24 fontCol6">北京市基本医疗保险参保人（含职工医保、居民医保等）</div>
        </div>
        <div class="text-flex">
          <div class="num font24 fontCol6">2、</div>
          <div class="text font24 fontCol6">北京市医疗保障局等相关政府部门管理特定人群</div>
        </div>
        <div class="text-flex">
          <div class="num font24 fontCol6">3、</div>
          <div class="text font24 fontCol6">中央公费医疗参保人</div>
        </div>
        <div class="text-flex">
          <div class="num font24 fontCol6">4、</div>
          <div class="text font24 fontCol6 lineHei36">拥有北京户籍或持有北京市居住证且已参加异地基本医保的北京市新市民</div>
        </div>
        <div class="text-flex font20 fontCol9">注：②③④参保人群医保内责任只保障住院,其它保障待遇一样</div>
      </div>
    </div>
    <div class="slice-pic-box borderTom">
      <div class="slice-pic-tit" id="tab1" ref="tab1"><img src="@/assets/images/home/slice/tag_2.jpg" /></div>
      <img src="@/assets/images/home/slice/slice_03.jpg" />
      <img src="@/assets/images/home/slice/slice_04.jpg" />
      <div class="pic-option-box">
        <img src="@/assets/images/home/slice/slice_05.jpg" />
        <div class="pic-btn" style="--bottom:32;--left:90" @click="openFilePopup(123)"></div>
      </div>
      <img src="@/assets/images/home/slice/slice_06.jpg" />
      <div class="pic-option-box">
        <img src="@/assets/images/home/slice/slice_07.jpg" />
        <div class="pic-btn" style="--bottom:22;--left:90" @click="openFilePopup(103)"></div>
      </div>
      <img src="@/assets/images/home/slice/slice_08.jpg" />
      <div class="pic-option-box">
        <img src="@/assets/images/home/slice/slice_09.jpg" />
        <div class="pic-btn" style="--bottom:32;--left:90" @click="openFilePopup(14)"></div>
      </div>
      <div class="slice-pic-tit" id="tab2" ref="tab2"><img src="@/assets/images/home/slice/tag_3.jpg" /></div>
      <img src="@/assets/images/home/slice/slice_10.jpg" />
      <img src="@/assets/images/home/slice/slice_11.jpg" />
      <img src="@/assets/images/home/slice/slice_12.jpg" />
      <img src="@/assets/images/home/slice/slice_13.jpg" />
      <img src="@/assets/images/home/slice/slice_14.jpg" />
      <div class="slice-pic-tit" id="tab3" ref="tab3"><img src="@/assets/images/home/slice/tag_4.jpg" /></div>
      <img src="@/assets/images/home/slice/slice_15.jpg" />
      <img src="@/assets/images/home/slice/slice_16.jpg" />
      <img src="@/assets/images/home/slice/slice_17.jpg" />
      <img src="@/assets/images/home/slice/slice_18.jpg" />
      <img src="@/assets/images/home/slice/slice_19.jpg" />
      <img src="@/assets/images/home/slice/slice_20.jpg" />
      <img src="@/assets/images/home/slice/slice_21.jpg" />
    </div>
    <div class="option-submit">
      <div class="agreement-box" v-if="false">
        <div class="check-btn"><van-checkbox v-model="isAgree" checked-color="#ed0500" icon-size="16px" @click="onCheck"></van-checkbox></div>
        <div class="agreement-list font24 fontCol9">我已阅读并同意
          <span @click="openPopup">
            <span v-for="item in agreementFileCode.filter(it => it.value>2 && it.value<100)" :key="item.value">《{{item.text}}》</span>
          </span>
        </div>
        <div class="check-icon"><img src="@/assets/images/home/select-btn.png" /></div>
      </div>
      <div class="again-box">
        <div class="warn-icom"><img src="@/assets/images/home/warn.png" /></div>
        <div class="warn-text">
          <img src="@/assets/images/home/again.png" />
          <p>历史被保人信息，免填写，更方便</p>
        </div>
        <div class="again-btn" @click="getHistory">一键获取</div>
      </div>
      <div class="submit-box">
        <div class="price-box" @click="toMedical">
          <price :price="prem" unit="元/份" fontSize="44" style="margin-top: 4px;"></price>
        </div>
        <div :class="['submit-btn font36 fontColF',isExpire?'disabled':'']" @click="throttle(onInsured)">
          {{isExpire?'已结束投保':'立即投保'}}</div>
        <!-- <div :class="['submit-btn font36 fontColF',isExpireReset?'disabled':'']" @click="throttle(onInsured)">
          {{isExpireReset?'已结束投保':'立即投保'}}</div> -->
        <!-- <div :class="['submit-btn font36 fontColF','disabled']">参保期结束</div> -->
      </div>
    </div>
    <div class="position-user" @click="toUser">
      <img src="@/assets/images/home/user.png" />
    </div>
    <van-popup v-model="isPopup" position="bottom" :style="{ height: '100%' }" @closed="closePopup">
      <div class="popup-box" :key="initPopup">
        <div class="tabs-box">
          <van-tabs v-model="tabsActive" color="#FD3147" title-active-color="#FD3147" title-inactive-color="#666666">
            <van-tab v-for="(item,index) in agreementFileCode.slice(0,2)" :title="item.text" :name="index"
              :key="item.value">
              <div class="tab-content">
                <tbxz v-if="item.value==1" :companyCode="companyCode"></tbxz>
                <tbyd v-else-if="item.value==2" :companyCode="companyCode"></tbyd>
                <div v-else class="pdf-container">
                  <pdf v-for="i in item.page" :key="i" :src="item.url" :page="i"></pdf>
                </div>
              </div>
            </van-tab>
            <van-tab title="其它" name="other">
              <div class="tab-content">
                <van-cell v-for="(item,index) in agreementFileCode.filter(it => it.value>2)" :key="item.value"
                  :value="item.text" @click="openFilePopup(item.value)" />
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <div class="option-box">
          <div class="option-btn active font32 fontColF width100" @click="onAgree">阅读并同意</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="filePopup" position="bottom" :style="{ height: '100%' }">
      <div class="popup-box" :key="filePopupKey">
        <div class="popup-tit-box font30 fontCol6">{{fileObj.text}}</div>
        <div class="file-content">
          <grys v-if="fileObj.text=='个人信息保护政策'"></grys>
          <div class="pdf-container" v-else>
            <pdf v-for="i in fileObj.page" :key="i" :src="fileObj.url" :page="i"></pdf>
          </div>
          <!-- <iframe v-else :src="fileObj.html" width="100%" height="100%" frameborder="0" ></iframe> -->
        </div>
        <div class="option-box">
          <div class="option-btn active font32 fontColF width100" @click="filePopup=false">返回</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="noticePopup" round :close-on-click-overlay="false" position="bottom">
      <div class="popup-box noticePopup-box">
        <div v-if="!isExpire">
          <div class="notice-tit font36 fontCol3 fontBold">您即将进入投保流程</div>
          <div class="notice-text font28 fontCol6 lineHei40">请仔细阅读保险条款、保险须知等内容，为保障您的权益，您在销售页面的操作将会被记录并加密储存。</div>
          <!-- <div class="notice-text font24 fontColYel">温馨提示：投保期将于{{endDate | wordFormat}}结束</div> -->
          <div class="notice-text font24 fontColYel">温馨提示： 参保日期 2024年10月15日~{{endDate | wordFormat}}</div>
        </div>
        <div v-else>
          <div class="notice-tit font36 fontCol3 fontBold">本产品投保期已于{{endDate | wordFormat}}结束</div>
        </div>
        <div class="notice-btn font32 fontColF" @click="onConfirmNotice">{{isExpire?'确认':'确认并继续'}}</div>
      </div>
    </van-popup>
    <van-popup v-model="historyPopup" round :close-on-click-overlay="false" style="height: 45%;">
      <div class="popup-box historyPopup-box">
        <div class="popup-tit-box font32 fontCol6">选择被保人</div>
        <div class="popup-content-box">
          <template v-for="(item, index) in historyInsureds">
            <div class="his-item" :key="item.index">
              <van-checkbox v-model="item.checked" checked-color="#FD3147" icon-size="20px"
                @change="val => {checkChange(val, item)}">
                <div class="checkbox">
                  <div class="text-flex">
                    <div class="font32 fontCol6">被保人：</div>
                    <div class="font32 fontCol3">{{item.name}}</div>
                    <div class="tag">（{{item.relationShipDesc}}）</div>
                  </div>
                  <div class="text-flex">
                    <div class="font32 fontCol6">证件号：</div>
                    <div class="font32 fontCol3">{{item.identityCode}}</div>
                  </div>
                </div>
              </van-checkbox>
            </div>
          </template>
        </div>
        <div class="option-box">
          <div class="option-btn font32 fontCol6" @click="historyPopup=false">取消</div>
          <div class="option-btn active font32 fontColF" @click="hisConfirm">确定</div>
        </div>
      </div>
    </van-popup>
    <UPButton ref="customBtn" v-show="false" @click="btnClick" scope="scope.mobile,scope.auth">隐私信息授权</UPButton>
  </div>
</template>

<script>
import moment from 'moment';
import Config from '@/config/config.js'
import { recordMixin } from '@/utils/mixin/recordMixin.js'
import { mapMutations, mapGetters } from "vuex";
import { agreementFileCode, agreementFileCodePA, agreementFileCodeRS, agreementFileCodeRB, identityTypes, relationship, InsuranceCompany, prem, insureDto, insureds, mainCoverages, healthCareRS, healthCarePA, healthCareRB } from '@/utils/dictionary.js'
import throttle from '@/utils/function/throttle.js'
import price from '@/components/price.vue'
import insureForm from '@/components/insureForm.vue'
import tbxz from '@/components/agreement/tbxz/tbxz.vue'
import tbyd from '@/components/agreement/tbyd/tbyd.vue'
import grys from '@/components/agreement/grys/grys.vue'
import pdf from 'vue-pdf'
import storage from 'store';
export default {
  name: 'Home',
  mixins: [recordMixin],
  components: { price, insureForm, pdf, tbxz, tbyd, grys },
  data () {
    return {
      fileObj: {},
      onKey: 0,
      identityTypes: identityTypes,
      relationship: relationship,
      agreementFileCode: agreementFileCode,
      InsuranceCompany: InsuranceCompany,
      noticePopup: false,     // 通知模态框
      fixedCurrent: 0,        // 滚动定位项
      notice: false,          // 医保类型详情显示
      prem: prem,             // 保费
      companyCode: 9818,      // 投保保司
      productCode: '',        // 保司产品编码
      authorizationType: 0,   // 授权状态 0:待授权 1:授权中 2:授权成功
      isUserRead: true,       // 是否阅读隐私政策协议
      readType: 0,            // 协议阅读类型 0:勾选阅读 1:参保阅读
      isAgree: false,         // 是否同意协议
      isRead: [0, 0, 0],      // 协议是否已读
      isPopup: false,         // 协议浏览模态框
      filePopup: false,       // 投保说明文件浏览模态框
      filePopupKey: 0,        // 投保说明文件浏览模态框更新
      fileName: '',           // 文件名称
      initPopup: 0,           // 初始化协议浏览模态框
      tabsActive: 0,          // 协议当前浏览项
      proposer: {             // 投保人
        index: "1",                   // 投保人id
        name: "",                     // 姓名
        mobile: "",                   // 联系电话
        identityType: 11,             // 证件类型
        identityCode: "",             // 证件号
        birthday: "",                 // 出生日期
        age: null,                    // 年龄
        gender: 1,                    // 性别
        nationality: "CHN",           // 国籍 CHN
      },
      insureds: [             // 被保人
        // {
        // 	index: "1",                   // 投保人id
        // 	relationWithProposer: 1,      // 与投保人关系
        // 	sameAsProposer: 1,            // 是否同投保人
        // 	name: '',                     // 姓名
        // 	mobile: "",                   // 联系电话
        // 	identityType: 11,             // 证件类型
        // 	identityCode: "",             // 证件号
        // 	birthday: "",                 // 出生日期
        // 	age: null,                    // 年龄
        // 	gender: 1,                    // 性别
        // 	nationality: "CHN",           // 国籍 CHN
        // 	socialInsuranceType: 3,       // 医保类型
        // }
      ],
      isExpire: false,        // 投保是否到期
      isExpireReset: false,   // 投保是否到期
      historyPopup: false,    // 历史被保人选择模态框
      historyInsureds: [],    // 历史被保人数据
      maskDatas: [],          // 历史被保人加密数据
    }
  },
  computed: {
    ...mapGetters(['getAgentKey', 'getToken', 'getNotice', 'getUserInfo', 'endDate']),
    totalPrice () {            // 总保费
      let num = this.insureds.length
      return this.prem * num
    },
    relationList () {          //关系占用
      let arr = []
      this.insureds.forEach(item => {
        arr.push(item.relationWithProposer)
      })
      return arr
    }
  },
  watch: {
    tabsActive (n, o) {
      if (n != 'other') {
        let fileObj = this.agreementFileCode[n]
        this.onRecord('Refer', '阅读投保协议', fileObj.text)
      }
    }
  },
  filters: {
    relation (val) {
      if (val == 1) return '本人'
      if (val == 2) return '配偶'
      if (val == 3) return '父母'
      if (val == 4) return '子女'
    },
  },
  created () {
    let that = this
    let expire = this.endDate + ' 22:00:00'
    let expireReset = Config.endDateReset + ' 17:00:00'
    let n = moment().format('X');
    let e = moment(expire).format('X');
    let r = moment(expireReset).format('X');
    let timer = null;
    if (+n >= +e) this.isExpire = true
    else if (+n + 3600 >= +e) {
      setTimeout(() => {
        that.isExpire = true
      }, e * 1000 - n * 1000)
    }
    if (+n >= +r) this.isExpireReset = true
    else if (+n + 3600 >= +r) {
      setTimeout(() => {
        that.isExpireReset = true
      }, r * 1000 - n * 1000)
    }
  },
  mounted () {
    let that = this
    window.addEventListener('scroll', this.handleScroll)  // 监听页面滚动
    if (storage.get('proposer')) {
      let { identityCode, mobile, name } = storage.get('proposer')
      identityCode = window.atob(identityCode)
      mobile = window.atob(mobile)
      this.proposer.identityCode = identityCode
      this.proposer.mobile = mobile
      this.proposer.name = name
      this.onRecord('System', '投保人缓存信息填充')
    }
    if (!this.getNotice) this.noticePopup = true
    else this.$refs.customBtn.$el.click()
    this.onRecord('System', '打开小程序', '进入投保主页')
    upsdk.pluginReady(function () {
      upsdk.setTitleStyle({
        navBackgroundColor: '0xffec1503',
        appletStyle: 'white', // 可选，black-黑色主题，white-白色主题
        backBtnVisible: '0', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
        appletTitleBarVisible: '1',// 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示
      });
      // 设置分享链接
      let link = that.$serviceLink
      if (that.getAgentKey) link = link + '?agentKey=' + that.getAgentKey
      console.log('分享2', link)
      upsdk.setAppletShareBaseInfo({
        title: "北京普惠健康保",  //  分享的标题
        content: "政府指导，195元每年，最高保障300万元", //  分享的描述
        picUrl: "https://f2.youbaolian.top/ysf/img/ysf.png",  //  分享展示图片的url
        toLink: link,// 跳转地址
        shareIdList: ["0", "1", "3", "4", "5", "6", "7"], // 默认为全部渠道都有，可选值为：’0’,’1’,’3’,’4’,’5’,’6’,’7’
        success: function () { }
      })
    });
  },
  methods: {
    ...mapMutations(['setToken', 'setUserInfo', 'setNotice']),
    throttle (fun, time = 3000) {  // 节流
      throttle(fun, time)
    },
    onConfirmNotice () {  // 告知信息模态框确认
      let that = this
      this.onRecord('Click', '点击确认告知按钮')
      this.setNotice(true)
      this.noticePopup = false
      this.$refs.customBtn.$el.click()
    },
    postAuthorization () { // 发起手动授权
      this.$refs.customBtn.$el.click();
    },
    btnClick (event, err, result) {  // 系统授权
      console.log('手动授权操作:', result)
      if (result && result.code) {
        this.login(result.code)
      }
    },
    login (code) {  // 授权登录
      this.$API.login({ code: code, loginType: '1' }).then(res => {
        console.log('手动授权,res', res)
        let resData = res.data
        if (!resData.grantAuth) {
          if (resData.token) this.setToken(resData.token)
        } else {
          this.authorizationType = 2
          this.setUserInfo(resData)
          let { token, certTp, certId, mobile, realName } = resData
          this.setToken(token)
          if (!this.proposer.mobile) this.proposer.mobile = mobile
          if (!this.proposer.name) this.proposer.name = realName
          if (!this.proposer.identityCode && certTp == '01') this.proposer.identityCode = certId
          let identityCode = window.btoa(certId)
          let en_mobile = window.btoa(mobile)
          let name = realName
          let proposer = { identityCode, mobile: en_mobile, name }
          storage.set('proposer', proposer)
        }
      }).catch(err => {
        console.log('手动授权,err', err)
      })
    },
    getHistory (params) { // 获取往年被保人数据
      if (this.getToken && this.getUserInfo) {
        let params = { identityType: 11, identityCode: this.getUserInfo.certId, mobile: this.getUserInfo.mobile, relation: '', maskFlag: 1 }
        this.$API.relationCustomers(params).then(res => {
          let { maskDatas, relationCustomers } = res.data || {}
          if (!maskDatas) maskDatas = []
          if (!relationCustomers) relationCustomers = []
          if (maskDatas.length && maskDatas.length === relationCustomers.length) {
            relationCustomers.forEach((it, ind) => {
              it.maskData = maskDatas[ind]
              it.identityType = +it.identityType
              it.relationWithProposer = +it.relationWithProposer
              it.socialInsuranceType = +it.socialInsuranceType
              it.checked = false
            })
            this.historyInsureds = relationCustomers
            this.maskDatas = maskDatas
            this.historyPopup = true
          } else {
            this.$toast.fail('抱歉，未搜索到匹配人员')
          }
        })
      }
      else this.$refs.customBtn.$el.click();
    },
    toUser () {
      if (this.getToken && this.getUserInfo) this.$router.push({ path: '/personal' })
      else this.$refs.customBtn.$el.click();
    },
    toOrder () {  // 前往订单页
      if (this.getToken) this.$router.push({ path: '/order' })
      else this.$refs.customBtn.$el.click();
    },
    CompanyCelect (item) {  // 保司切换
      // console.log('保司切换',item)
      this.companyCode = item.value
      this.productCode = item.code
      if (item.value == 9818) this.agreementFileCode = agreementFileCodeRS
      else if (item.value == 9803) this.agreementFileCode = agreementFileCodePA
      else this.agreementFileCode = agreementFileCodeRB
      // this.agreementFileCode = item.value==9818?agreementFileCodeRS:agreementFileCodePA
      this.isAgree = false
      this.isRead = [0, 0, 0],
        this.onRecord('Click', '选择保司', item.text)
    },
    openFilePopup (key) {  // 打开投保信息说明文件模态框
      this.fileObj = this.agreementFileCode.filter(it => it.value == key)[0]
      // console.log(this.fileObj)
      this.filePopup = true
      this.filePopupKey++
      if (key >= 100) this.onRecord('Click', '查看投保资料', this.fileObj.text)
      else this.onRecord('Refer', '阅读投保协议', this.fileObj.text)
    },
    openPopup () {  // 打开协议浏览模态框
      this.readType = 0
      this.isPopup = true
      this.onRecord('Click', '打开查看协议')
      this.onRecord('Refer', '阅读投保协议', this.agreementFileCode[0].text)
    },
    onCheck (e) {  // 阅读协议复选框点击
      if (this.isAgree) {
        this.isAgree = !this.isAgree
        this.isPopup = true
        this.onRecord('Click', '勾选阅读并同意协议')
        this.onRecord('Click', '打开查看协议')
        this.onRecord('Refer', '阅读投保协议', this.agreementFileCode[0].text)
      }
      else {
        this.isRead = [0, 0, 0]
        this.onRecord('Click', '取消勾选阅读并同意协议')
      }
    },
    onAgree () {  // 阅读并同意协议
      if (this.tabsActive != 'other') {
        this.isRead[+this.tabsActive] = 1
        this.onRecord('Click', '协议阅读并同意', this.agreementFileCode[this.tabsActive].text)
      }
      else {
        this.isRead[this.isRead.length - 1] = 1
        this.onRecord('Click', '协议阅读并同意', '其它协议')
      }
      let i = this.isRead.findIndex(it => { return !it })
      if (i != -1) i == this.isRead.length - 1 ? this.tabsActive = 'other' : this.tabsActive = i
      else {
        this.isAgree = true
        this.isPopup = false
        if (this.readType) this.onSubmit()
      }
    },
    closePopup () {  // 关闭协议浏览模态框
      // this.isRead = false
      this.tabsActive = 0
      this.readType = 0
      this.initPopup++
    },
    onScroll (e) {  // 协议浏览滚动监听
      let el = e.target;
      if (el.scrollTop + el.clientHeight >= el.scrollHeight - 10) {
        this.isRead = true
      }
    },
    addInsureds () {  // 添加被保人
      if (this.authorizationType != 2) return this.$refs.customBtn.$el.click(); // 授权登录
      if (!this.isUserRead) {
        this.tabClick(0)
        return this.$toast('请先阅读并确认《隐私政策》、《用户协议》及《客户告知书》');
      }
      if (this.insureds.length >= 10) return this.$toast('一次最多只能给10人投保，请下次提交~');
      let item = JSON.parse(JSON.stringify(insureds))
      // if (this.companyCode == 9818) item.socialInsuranceType = healthCareRS[0].value
      // else if (this.companyCode == 9803) item.socialInsuranceType = healthCarePA[0].value
      // else item.socialInsuranceType = healthCareRB[0].value
      if (this.relationList.findIndex(it => { return it == 1 }) != -1) {  // 有本人
        item.sameAsProposer = 0
        if (this.relationList.findIndex(it => { return it == 2 }) != -1) {  // 有配偶
          if (this.relationList.filter(it => it == 3).length >= 4) item.relationWithProposer = 4  // 已添加4位父母
          else item.relationWithProposer = 3
        }
        else item.relationWithProposer = 2  // 无配偶
      }
      this.insureds.push(item)
      this.onRecord('Click', '添加被保人')
    },
    delInsureds (i) {  // 删除被保人
      this.insureds.splice(i, 1)
      this.onKey++
      this.onRecord('Click', '删除被保人')
    },
    checkChange (val, item) { // 历史被保人选择过滤
      if (val && (+item.relationWithProposer === 1 || +item.relationWithProposer === 2)) {
        let e = this.historyInsureds.filter(it => (+it.relationWithProposer === +item.relationWithProposer) && it.index !== item.index)
        if (e.length) e[0].checked = false
      }
    },
    hisConfirm () { // 历史被保人选择确定
      let list = this.historyInsureds.filter(it => it.checked)
      this.insureds.push(...list)
      this.historyPopup = false
    },
    onValidIdCard () {
      let ins = this.$refs.insureFormI
      ins.forEach(it => it.$refs.insureForm.validate('identityCode'))
    },
    async onPromise (list) {
      let fail = [], num = 0
      return await new Promise((resolve, reject) => {
        list.forEach(async (item, index) => {
          let status = await item.onValidate(this.proposer)
          if (!status) fail.push(index)
          num++
          if (num == list.length) {
            fail.sort((x, y) => x - y)
            resolve(fail);
          }
        })
      });
    },
    async onInsured () {  // 立即参保 420702199210045216  420702199201106451
      this.onRecord('Click', '立即参保')
      if (this.authorizationType != 2) return this.$refs.customBtn.$el.click(); // 授权登录
      if (!this.isUserRead) {
        this.tabClick(0)
        return this.$toast('请先阅读并确认《隐私政策》、《用户协议》及《客户告知书》');
      }
      if (!this.insureds.length) {
        this.tabClick(0)
        return this.$toast('请先添加被保人信息')
      }
      let statusP = await this.$refs.insureFormP.onValidate()
      if (!statusP) {  // 投保人信息验证不通过
        this.$notify({ type: 'warning', message: '请检查投保人信息是否有误' });
        let scrollBy = this.$refs.proposer.getBoundingClientRect().top - 70
        window.scrollBy({ top: scrollBy, left: 0, behavior: "smooth" });
        return false
      }
      let statusI = await this.onPromise(this.$refs.insureFormI)
      if (statusI.length) {  // 被保人信息验证不通过
        let ind = statusI[0], relationWithProposer = this.insureds[statusI[0]].relationWithProposer
        let nice = this.relationship.filter(it => it.value == relationWithProposer)[0].text
        this.$notify({ type: 'warning', message: `请检查被保人${nice}信息是否有误` });
        let scrollBy = this.$refs['insured' + ind][0].getBoundingClientRect().top - 60
        window.scrollBy({ top: scrollBy, left: 0, behavior: "smooth" });
        return false
      }
      let identityCode = window.btoa(this.proposer.identityCode)
      let mobile = window.btoa(this.proposer.mobile)
      let name = this.proposer.name
      let proposer = { identityCode, mobile, name }
      storage.set('proposer', proposer)
      if (!this.isAgree) {
        this.readType = 1
        this.isPopup = true
      }
      else this.onSubmit()
    },
    onSubmit () {  // 提交投保信息
      if (this.isExpire) return this.$toast('非常抱歉，本期投保期限已过，敬请下期开放！')
      this.onRecord('Click', '提交投保信息')
      let insureDtoItem = JSON.parse(JSON.stringify(insureDto))
      insureDtoItem.firstYearPremium = this.totalPrice
      // insureDtoItem.companyCode = this.companyCode
      // insureDtoItem.productCode = this.productCode
      let proposer = this.proposer
      let insureds = this.insureds
      let mainCoveragesList = []
      insureds.forEach(item => {
        if (item.sameAsProposer === 1) {
          for (var p in proposer) {
            item[p] = proposer[p];
          }
        }
        let mainCoveragesItem = JSON.parse(JSON.stringify(mainCoverages))
        mainCoveragesItem.prem = this.prem
        mainCoveragesItem.insuredId = item.index
        // mainCoveragesItem.productCode = this.productCode
        // mainCoveragesItem.masterProductCode = this.productCode
        mainCoveragesList.push(mainCoveragesItem)
      })
      let params = Object.assign({}, insureDtoItem, { proposer: proposer }, { insureds: insureds }, { mainCoverages: mainCoveragesList }, { maskDatas: this.maskDatas })
      console.log('投保数据', params)
      params.sceneId = storage.get('sceneId') || null
      if (this.getAgentKey) params.agentKey = this.getAgentKey
      // let params = { mergeDto}
      this.$API.onSubmitBills(params).then(res => {
        console.log('res => ', res)
        this.isPopup = false
        let { orderCode, id } = res.data
        this.putRecord(id)
        this.$router.push({ path: '/unpaid', query: { orderId: id, orderCode: orderCode } })
      }).catch(err => {
        console.log('err => ', err)
      })
    },
    tabClick (e) {  // tags导航栏切换
      let scrollBy = this.$refs["tab" + e].getBoundingClientRect().top - 50
      window.scrollBy({
        top: scrollBy,
        left: 0,
        behavior: "smooth"
      });
    },
    handleScroll () { // offsetTop
      let tab1 = document.getElementById('tab1').offsetTop - 100
      let tab2 = document.getElementById('tab2').offsetTop - 100
      let tab3 = document.getElementById('tab3').offsetTop - 100
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log('滚动距离:',scrollTop,this.fixedCurrent)
      if (scrollTop < tab1 && this.fixedCurrent != 0) this.fixedCurrent = 0
      if (scrollTop > tab1 && scrollTop < tab2 && this.fixedCurrent != 1) this.fixedCurrent = 1
      if (scrollTop > tab2 && scrollTop < tab3 && this.fixedCurrent != 2) this.fixedCurrent = 2
      if (scrollTop > tab3 && this.fixedCurrent != 3) this.fixedCurrent = 3
    },
    callPhone () {  // 联系客服
      this.onRecord('Click', '联系客服')
      window.location.href = 'tel:4008-315866'
    },
    toMedical () {
      return false
      location.href = "https://hmbcity.webao99.com/bj24/front-insured/?flag=OUT&channelType=PRIVATE&orderChannel=H5&projectid=124293784147386368&promotionId=AGENT_LEADER_125696249281142785&skuNo=BXHM0031#/home/124580188672811008"
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="less" scoped>
.home-main {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 266px;
  background-color: #ffffff;
  .slice-pic-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    .slice-pic-tit {
      width: 100%;
      height: 120px;
    }
    .pic-option-box {
      width: 100%;
      display: flex;
      position: relative;
      .pic-btn {
        position: absolute;
        bottom: calc(var(--bottom) * 1px);
        left: calc(var(--left) * 1px);
        width: 400px;
        height: 90px;
        // background-color: rgba(0,0,0,0.5);
      }
      .text-btn {
        position: absolute;
        top: calc(var(--top) * 1px);
        right: calc(var(--right) * 1px);
        width: 220px;
        height: 90px;
        // background-color: rgba(0,0,0,0.5);
      }
    }
    &.borderTom {
      border-bottom: 16px solid #f5f5f5;
    }
  }
  .tags-box {
    height: 100px;
    background-color: #ffffff;
    padding-top: 24px;
    border-bottom: 1px solid #e5e5e5;
    .tags-content {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      position: relative;
      .tags-item {
        flex: 1;
        height: 100%;
        text-align: center;
        line-height: 42px;
        &.active {
          color: #ed0500;
        }
      }
      .tags-line {
        position: absolute;
        width: 48px;
        height: 4px;
        bottom: 0;
        top: 48px;
        background-color: #ed0500;
        border-radius: 4px;
        transform: translateX(calc(var(--ind) * 25vw + 68px));
        transition-duration: 0.2s;
      }
    }
  }
  .insuranceCompany-list {
    width: 100%;
    padding: 12px 40px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 20px solid #f7f7f7;
    .insuranceCompany-item {
      width: 208px;
      height: 92px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      &.company9803 {
        --color: #ffd4c5;
        background-image: url("~@/assets/images/home/pa.png");
        &.active {
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            right: 12px;
            bottom: 12px;
            background-color: var(--color);
          }
        }
      }
      &.company9818 {
        --color: #c4f2ff;
        background-image: url("~@/assets/images/home/rs.png");
        &.active {
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            right: 12px;
            bottom: 12px;
            background-color: var(--color);
          }
        }
      }
      &.company9801 {
        --color: #ffb4b4;
        background-image: url("~@/assets/images/home/rb.png");
        &.active {
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            right: 12px;
            bottom: 12px;
            background-color: var(--color);
          }
        }
      }
    }
  }
  .option-submit {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 8;
    .agreement-box {
      width: 100%;
      position: relative;
      padding: 24px 32px;
      background-color: #fafafa;
      border-top: 1px solid #e6e6e6;
      display: flex;
      align-items: flex-start;
      .check-btn {
        min-width: 48px;
        height: 48px;
      }
      .agreement-list {
        line-height: 32px;
        span {
          color: #d41d11;
        }
      }
      .check-icon {
        width: 120px;
        height: 48px;
        position: absolute;
        top: -32px;
        left: 12px;
      }
    }
    .submit-box {
      border-top: 1px solid #f5f5f5;
      height: 120px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      // padding-right: 24px;
      .price-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 16px;
        .font20 {
          transform-origin: center center;
        }
      }
      .submit-btn {
        width: 314px;
        height: 92px;
        border-radius: 92px;
        background-color: #ed0500;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-right: 36px;
        &.disabled {
          pointer-events: none;
          background-color: #dddddd;
          font-size: 30px;
        }
      }
    }
    .again-box {
      width: 100%;
      height: 140px;
      background: linear-gradient(90deg, #ffe286, #ffca5e);
      border-radius: 16px 16px 0 0;
      padding: 0 24px 0 16px;
      display: flex;
      align-items: center;
      .warn-icom {
        width: 96px;
        height: 96px;
      }
      .warn-text {
        flex: 1;
        padding: 0 12px;
        p {
          margin: 0;
          font-size: 28px;
          color: #774112;
        }
      }
      .again-btn {
        width: 160px;
        height: 64px;
        border-radius: 64px;
        line-height: 64px;
        text-align: center;
        color: #ffedb8;
        background-color: #d41d11;
      }
    }
  }
}
.user-agreement-box {
  width: 100%;
  // display: flex;
  align-items: center;
  padding: 12px 32px 0;
  /deep/ .van-checkbox {
    align-items: flex-start;
    .van-checkbox__icon {
      height: 20px;
      display: flex;
      align-items: center;
    }
  }
  .agreement-item {
    color: #ed0500;
  }
  &.agreement-box {
    width: 100%;
    position: relative;
    padding: 24px 32px;
    display: flex;
    align-items: flex-start;
    .check-btn {
      min-width: 48px;
      height: 48px;
    }
    .agreement-list {
      line-height: 32px;
      span {
        color: #d41d11;
      }
    }
    .check-icon {
      width: 120px;
      height: 48px;
      position: absolute;
      top: -32px;
      left: 12px;
    }
  }
}
.form-box {
  width: 100%;
  margin-top: 40px;
  padding: 0 20px;
  .form-card {
    min-height: 180px;
    background-color: #ffffff;
    border-radius: 16px;
    .form-tit {
      width: 100%;
      display: flex;
      .serial-nub {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #5a4444;
        background-color: #ffded9;
        margin-right: 16px;
      }
      .tit {
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        span {
          font-size: 28px;
          color: #666666;
          margin-left: 8px;
        }
      }
    }
    .product-box {
      width: 100%;
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .product-item {
        width: 304px;
        height: 292px;
        padding: 24px;
        // background-image: url('~@/assets/images/home/product1-bj.png');
        --color: #666666;
        &.active {
          // background-image: url('~@/assets/images/home/product-bj.png');
          --color: #ffffff;
        }
        .price-box {
          display: flex;
          align-items: flex-end;
          color: var(--color);
          .price {
            font-size: 48px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            line-height: 48px;
            margin-right: 8px;
          }
          .unit {
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            line-height: 32px;
          }
        }
        .level-box {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 28px;
          margin-top: 16px;
          margin-bottom: 20px;
          color: var(--color);
        }
        .content {
          padding-left: 16px;
          position: relative;
          margin-top: 10px;
          color: var(--color);
          &::after {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: var(--color);
            position: absolute;
            left: -2px;
            top: 6px;
          }
        }
      }
    }
    .form-content {
      margin-top: 24px;
      position: relative;
      .verifyMasking {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
      }
      /deep/ .van-cell {
        padding-left: 0;
        padding-right: 0;
        font-size: 16px;
        &::after {
          left: 0;
          right: 0;
        }
      }
      /deep/ .van-dropdown-menu {
        width: 100%;
        .van-dropdown-menu__bar {
          box-shadow: none;
          height: initial;
          .van-dropdown-menu__title {
            width: 100%;
            padding: 0;
            padding-right: 16px;
            &::after {
              right: 4px;
            }
          }
        }
        .van-dropdown-item {
          .van-dropdown-item__content {
            padding: 10px 28px;
          }
        }
      }
      .insureds-item {
        margin-top: 32px;
        position: relative;
        .mark-box {
          width: 200px;
          height: 56px;
          display: flex;
          align-items: center;
          background-image: url("~@/assets/images/home/insureds-bj.png");
          .mark-icon {
            width: 28px;
            height: 28px;
            margin-left: 24px;
            background-image: url("~@/assets/images/home/people.png");
            margin-right: 8px;
          }
          .mark-text {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 28px;
          }
        }
        .del-btn {
          width: 56px;
          height: 56px;
          padding: 8px;
          position: absolute;
          right: 0;
          top: 0;
        }
        .relation-box {
          margin: 24px 0;
          display: flex;
          align-content: center;
          justify-content: space-between;
          .tags-item {
            font-size: 32px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            background-color: #f8f8f8;
            color: #999999;
            line-height: 32px;
            padding: 16px 24px;
            border-radius: 8px;
            &.active {
              background-color: #3377ff;
              color: #ffffff;
            }
          }
        }
      }
    }
    .add-btn {
      width: 670px;
      height: 88px;
      color: #ffffff;
      background-color: #ed0500;
      border: 2px solid #ed0500;
      border-radius: 55px;
      margin: 48px auto 0;
      line-height: 88px;
      text-align: center;
    }
  }
}
.notice-box {
  width: 100%;
  padding: 24px;
  background-color: #fff0e6;
  .notice-tit-box {
    width: 100%;
    display: flex;
    align-items: center;
    .notice-icon {
      // width: 32px;
      // height: 32px;
      width: 16px;
      height: 16px;
      background-image: url("~@/assets/images/home/notice.png");
      margin-right: 8px;
    }
    .notice-tit {
      flex: 1;
    }
    .notice-option {
      color: #ed0500;
    }
  }
  .notice-content {
    height: 0px;
    transition: all 0.3s;
    overflow: hidden;
    &.active {
      height: 264px;
    }
    .text-flex {
      display: flex;
      align-items: flex-start;
      padding-top: 12px;
      &:first-child {
        padding-top: 20px;
      }
      &:last-child {
        padding-bottom: 20px;
      }
      .num {
        min-width: 30px;
        max-width: 30px;
      }
    }
  }
}
.position-user {
  width: 96px;
  height: 102px;
  position: fixed;
  right: 40px;
  bottom: 25%;
  z-index: 9;
  img {
    width: 100%;
    height: 100%;
  }
}
.popup-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .popup-tit-box {
    width: 100%;
    min-height: 92px;
    border-bottom: 8px solid #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .popup-content-box {
    width: 100%;
    padding: 48px 36px 36px;
    box-sizing: border-box;
    flex: 1;
  }
  .file-content {
    flex: 1;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .tabs-box {
    flex: 1;
    overflow: hidden;
    /deep/ .van-tabs {
      height: 100%;
      display: flex;
      flex-direction: column;
      .van-tabs__line {
        bottom: 18px;
      }
      .van-tabs__content {
        flex: 1;
        overflow: hidden;
        .van-tab__pane {
          height: 100%;
          padding: 12px 16px;
          background-color: #f2f1f6;
        }
      }
    }
    .tab-content {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      border-radius: 4px;
      padding: 12px 12px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      scrollbar-width: none;
      -ms-overflow-style: none;
      .pdf-container {
        position: relative;
        iframe {
          width: 100vw;
          min-height: 100vh;
        }
      }
    }
  }
  .option-box {
    width: 100%;
    min-height: 120px;
    padding: 0 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .option-btn {
      width: 260px;
      height: 88px;
      background-color: #ffffff;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 2px #dddddd;
      box-sizing: border-box;
      &.active {
        background-color: #fd3147;
        border: none;
      }
      &.disabled {
        // pointer-events: none;
      }
    }
  }
}
.noticePopup-box {
  padding: 48px 36px 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 280px;
  .notice-text {
    margin-top: 24px;
  }
  .notice-btn {
    width: 100%;
    height: 98px;
    background-color: #ed0500;
    border-radius: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
  }
}
.historyPopup-box {
  width: 686px;
  .popup-tit-box {
    border-bottom: 2px solid #efefef;
  }
  .his-item {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 30px 0px #cddfff;
    border-radius: 16px;
    padding: 36px 24px;
    box-sizing: border-box;
    & + .his-item {
      margin-top: 24px;
    }
    .checkbox {
      width: 100%;
      .text-flex {
        width: 100%;
        display: flex;
        align-items: center;
        & + .text-flex {
          margin-top: 12px;
        }
      }
    }
  }
  .option-box {
    border-top: 2px solid #efefef;
    .option-btn {
      height: 80px;
    }
  }
}
.bgd-image {
  background-size: 100% 100%;
}
.divide {
  border-bottom: 20px solid #f7f7f7;
}
.marginT52 {
  margin-top: 52px;
}
.marginB24 {
  margin-bottom: 24px !important;
}
.lineHei36 {
  line-height: 36px;
}
.lineHei40 {
  line-height: 40px;
}
.paddingB12 {
  padding-bottom: 12px;
}
.paddingB24 {
  padding-bottom: 24px;
}
.paddingB40 {
  padding-bottom: 40px;
}
.inset-shadow {
  // box-shadow: 0px 2px 30px 0px #F0F5FF;
  box-shadow: 0px 2px 30px 0px rgba(243, 18, 26, 0.1);
  border-radius: 16px;
  overflow: hidden;
}
.width100 {
  width: 100% !important;
}
</style>
