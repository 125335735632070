let endDate           // 投保结束日期
let serviceLink       // 小程序首页链接
let appId             // 小程序ID
let endDateReset      // 重置投保结束日期

if (process.env.NODE_ENV === 'production') { // 生产环境
	endDate = '2025-1-27'
	endDateReset = '2025-1-31'
	serviceLink = 'http://uphb.joinusibc.com/#/'
	appId = 'fbbb35978ac3539d'
} else { // development or test | 开发环境 or 测试环境
	endDate = '2025-1-27'
	endDateReset = '2025-1-31'
	serviceLink = 'http://huiminbao.youbaolian.top/#/'
	appId = 'b364a3006a6e186f'
}

export default {
	endDate, serviceLink, appId, endDateReset
}