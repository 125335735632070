<template>
  <div class="order-main">
    <!-- <van-sticky> -->
    <div class="order-tabs">
      <van-tabs :active="active" @change="tabsChange">
        <van-tab title="全部" name="1"></van-tab>
        <van-tab title="待支付" name="2"></van-tab>
        <van-tab title="已支付" name="3"></van-tab>
        <van-tab title="已关闭" name="4"></van-tab>
        <van-tab title="已取消" name="5"></van-tab>
      </van-tabs>
    </div>
    <!-- </van-sticky> -->
    <van-skeleton title avatar round :row="3" :loading="showSkeleton">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-if="orderList.length" :loading="loading" :finished="finished" finished-text="--全部加载完成--" :immediate-check="false" @load="onLoad">
          <img src="../../assets/images/order/banner.png" alt="" class="banner" @click="toHome">
          <div class="order-item" :class="setCardBackground(item)" v-for="(item, index) in orderList" :key="index" @click="goDetails(item)">
            <div class="order-item__main">
              <div class="order-item__main__header">
                <img src="../../assets/logo.png" class="logo">
                <span>{{item.productName}}</span>
              </div>
              <div class="order-item__main__content" v-for="(c, serial) in item.insuredSubOrders" :key="serial">
                <div class="order-item__main__content__top flex">
                  <div>被保人：{{c.insuredName}}</div>
                  <div>¥{{c.firstYearPremium}}</div>
                </div>
                <div class="order-item__main__content__bottom flex">
                  <div>{{c.insuredIdentityType | identityType}}：{{c.insuredIdentityCode}}</div>
                  <div :class="c.payStatus!==1&&c.payStatus!==3?'highlight':''">{{c.orderStatus | orderLowerStatus}}</div>
                </div>
              </div>
              <div class="order-item__main__total">
                <span class="order-item__main__total__name">合计：</span>￥{{item.total}}
              </div>
            </div>
            <div class="order-item__pay flex" v-if="item.tocBusinessStatus===2">
              <div class="order-item__pay__left">
                <img src="../../assets/images/order/bitmap.png">
                <span class="order-item__pay__left__name">距失效还剩</span><van-count-down :time="item.payUrlExpireTime" @finish="onFinish(item)" />
              </div>
              <div class="order-item__pay__btn" @click.stop="onPay(item)">去支付</div>
            </div>
          </div>
        </van-list>
        <div class="default" v-if="!orderList.length">
          <img src="../../assets/images/order/banner.png" alt="" class="banner" @click="toHome">
          <img src="../../assets/images/order/default.png" class="default__img">
          <div class="default__text">{{loading?'加载中...':'暂无数据~'}}</div>
        </div>
      </van-pull-refresh>
    </van-skeleton>
    <van-popup v-model="noticePopup" round :close-on-click-overlay="true" position="bottom" :style="{ height: popup.height }">
      <div class="popup-box noticePopup-box">
        <div v-if="popup.type===1">
          <div class="notice-tit font36 fontCol3 fontBold">退保步骤</div>
          <div class="noticePopup-content">
            <div class="notice-text font28 fontCol6 lineHei40">
              <div class="icon-index font26">1</div>联系保险公司申请退保
            </div>
            <div class="notice-text font28 fontCol6 lineHei40">
              <div class="icon-index font26">2</div>保险公司同意退保
            </div>
            <div class="notice-text font28 fontCol6 lineHei40">
              <div class="icon-index font26">3</div>银联退款<span class="low-level">（资金预计1～2个工作日原路退回）</span>
            </div>
          </div>
        </div>
        <div v-if="popup.type===2">
          <div class="notice-tit font36 fontCol3 fontBold">重新投保</div>
          <div class="noticePopup-content">
            <div class="notice-text font28 fontCol6 lineHei40 start">
              <div class="notice-text-title fontCol3">失败原因：</div>
              <div class="notice-text-details">
                北京普惠健康保参保资格校验不通过，根据医保局资格查询，参保人正确的参保类型为：{{popup.item.insuresMessage.retrySocialInsuranceName}}</div>
            </div>
            <div class="notice-text font28 fontCol6 lineHei40 start">
              <div class="notice-text-title fontCol3">投保建议：</div>
              <div class="notice-text-details">系统已将原有“{{popup.item.insuresMessage.socialInsuranceName}}”参保类型调整为：
                <span class="fontCol3">{{popup.item.insuresMessage.retrySocialInsuranceName}}</span>
              </div>
            </div>
            <div class="notice-text font28 fontCol6 lineHei40 start">
              <div class="notice-text-title fontCol3"></div>
              <div class="notice-text-details">点击下方“重新投保”按钮再次投保</div>
            </div>
          </div>
        </div>
        <div class="notice-btn font32 fontColF" v-if="popup.type===1" @click="callPhone()">联系保险公司</div>
        <div class="notice-btn font32 fontColF" v-if="popup.type===2" @click="applicationForm()">重新投保</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment';
import { supplierContactMobile } from '@/filters'
import { recordMixin } from '@/utils/mixin/recordMixin.js'
import { mapGetters } from "vuex";
import { Dialog } from 'vant'
export default {
  name: 'Order',
  mixins: [recordMixin],
  data () {
    return {
      orderList: [],
      pageNum: 1,
      pageSize: 20,
      popup: {
        item: null,
        type: 0,
        height: '34%'
      },
      showSkeleton: false, // 骨架
      isClearList: false, // 是否清空List
      loading: false, // 滚动加载中
      finished: false, // 滚动加载完成
      isLoading: false, // 下拉强制刷新
      noticePopup: false, // 通知模态框
      isExpire: false,
      active: ''
    }
  },
  computed: {
    ...mapGetters(['endDate, getAgentKey']),
  },
  created () {
    let that = this
    let expire = this.endDate + ' 22:00:00'
    let n = moment().format('X');
    let e = moment(expire).format('X');
    if (+n >= +e) {
      this.isExpire = true
      that.invalidityTime = 0
    }
    else if (+n + 3600 >= +e) {
      setTimeout(() => {
        that.isExpire = true
        that.invalidityTime = 0
      }, e * 1000 - n * 1000)
    }
  },
  mounted () {
    upsdk.pluginReady(function () {
      upsdk.setTitleStyle({
        navBackgroundColor: '0xffFFFFFF',
        appletStyle: 'black', //可选，black-黑色主题，white-白色主题
        backBtnVisible: '1', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
        appletTitleBarVisible: '1',// 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示
      })
    })
    this.queryList()
  },
  methods: {
    setCardBackground (row) {
      if (row.tocBusinessStatus === 2) { // 待支付
        return 'warning'
      } else if (row.tocBusinessStatus === 4 || row.tocBusinessStatus === 5) { // 已关闭 已取消
        return 'info'
      }
    },
    goDetails (row) {
      this.$router.push({ path: '/order/details', query: { orderId: row.orderId } })
    },
    tabsChange (e) {
      this.active = e
      this.pageNum = 1
      this.orderList = []
      this.queryList()
    },
    examineIssue (row) {
      Dialog.alert({
        message: row.message,
      }).then(() => {
        // on close
      })
    },
    onFinish (row) {
      row.insuredSubOrders.map(c => {
        c.orderStatus = 102
      })
      row.insuredSubOrders = Object.assign([], row.insuredSubOrders)
      row.tocBusinessStatus = 5
    },
    onLoad () {
      if (!this.isClearList) {
        this.queryList()
      }
    },
    onRefresh () {
      this.pageNum = 1
      this.finished = false
      this.loading = false
      this.isLoading = false
      this.isClearList = true
      console.log(this.pageNum)
      this.queryList()
    },
    queryList () {
      this.loading = true
      let params = {
        page: this.pageNum || 1,
        size: this.pageSize,
        queryStatus: this.active
      }
      this.$API.orderList(params).then(res => {
        console.log(res.data)
        this.loading = false
        if (!res.data || !res.data.records.length) {
          this.finished = true
          return
        }
        // 如果是下拉刷新就清空列表
        if (this.isClearList) {
          this.orderList = []
        }
        this.pageNum = this.pageNum + 1
        let list = (res.data && res.data.records) || []
        // list.map(c => {
        //   c.countDown = c.payUrlExpireTime ? c.payUrlExpireTime - Date.now() : ''
        // })
        this.orderList = this.orderList.concat(list)
        // 所有数据都加载完成后就不需要滚动加载
        if (this.orderList.length >= res.data.total) {
          this.finished = true
        }
        this.isClearList = false
      }).catch(err => {
        this.loading = false
        this.finished = true
        this.isClearList = false
        console.log('err => ', err)
      })
    },
    goPdf (row) {
      this.$router.push({ path: '/pdf', query: { url: row.elePolicyUrl } })
      // window.location.href = 'http://f4.youbaolian.cn/chexian/electronicInsurance/9803/23825/9803_10121003901809327131.PDF'
    },
    onPay (row) {
      if (this.isExpire) return this.$toast('非常抱歉，本期投保期限已过，敬请下期开放！')
      this.onRecord('Click', '立即支付')
      let that = this
      let params = {
        orderId: row.orderId,
        paymentMethod: 3, // 付款方式 1-支付宝 2-微信 3-云闪付
        platform: 1 // 支付交易平台 1-云闪付 2-支付宝 3-微信 4-保司收银台 5-银联商务
      }
      this.$API.getPayTN(params).then(res => {
        let tn = res.data.platformBillNo
        console.log('res => ', tn)
        upsdk.pay({
          tn: tn,
          success: function () {
            console.log('支付成功')
            that.onRecord('System', '支付结果', '支付成功')
            that.putRecord(row.orderId)
            that.$router.replace({ path: '/paid', query: { orderId: row.orderId } })
          },
          fail: function (err) {
            console.log('支付失败')
            that.onRecord('System', '支付结果', '支付失败')
            that.putRecord(row.orderId)
          }
        });
      }).catch(err => {
        console.log('err => ', err)
      })
    },
    toHome () {
			let that = this
      // this.$router.push({ path: '/' })
			upsdk.pluginReady(function () {
			  upsdk.appletSharePopup({
			  	success:function(){ 
			  		console.log('分享成功')
			  	},
			  	fail:function(){ 
			  		console.log('分享失败')
			  	}
			  })
			});
    },
    toService (row) {
      this.popup.type = 1
      this.popup.height = '34%'
      this.noticePopup = true
      this.popup.item = row
    },
    toAnew (row) {
      if (this.isExpire) return this.$toast('非常抱歉，本期投保期限已过，敬请下期开放！')
      this.popup.type = 2
      this.popup.height = '42%'
      this.noticePopup = true
      this.popup.item = row
    },
    callPhone () {  // 联系客服
      this.onRecord('Click', '联系客服')
      window.location.href = `tel:${supplierContactMobile(this.popup.item.companyCode)}`
      upsdk.pluginReady(function () {
        upsdk.setTitleStyle({
          navBackgroundColor: '0xffffffff',
          appletStyle: 'black', //可选，black-黑色主题，white-白色主题
          backBtnVisible: '1', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
          appletTitleBarVisible: '1', // 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示
        })
      })
    },
    applicationForm () {
      this.onRecord('Click', '重新投保')
      let params = {
        dto: {
          orderCode: this.popup.item.orderCode,
          retrySocialInsuranceType: this.popup.item.insuresMessage.retrySocialInsuranceType
        }
      }
      this.$API.addNewOrder(params).then(res => {
        this.onRecord('Click', '重新投保', `将原有“${this.popup.item.insuresMessage.socialInsuranceName}”参保类型调整为${this.popup.item.insuresMessage.retrySocialInsuranceName}`)
        let data = res.ReplyParams
        this.onPay(data)
      }).catch(err => {
        console.log('err => ', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.order-main {
  // min-height: 100vh;
  background-color: #f7f7f7;
  // overflow-y: auto;
  // padding-top: 32px;
  box-sizing: border-box;
  text-align: left;
  font-family: PingFangSC-Semibold, PingFang SC;
  .order-tabs {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: #fff;
  }
  /deep/ .van-pull-refresh {
    padding-top: 50px;
    height: 100vh !important;
    overflow: auto !important;
    background-color: #f7f7f7;
  }
  .banner {
    width: 710px;
    height: 289px;
    margin: 0 auto;
    display: block;
    margin-bottom: 33px;
  }
  .order-item {
    margin: 0 auto;
    margin-bottom: 25px;
    width: 690px;
    &__main {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      background-color: #fff;
      border-radius: 16px;
      padding: 32px;
      &__header {
        display: flex;
        align-items: center;
        .logo {
          width: 68px;
          height: 68px;
          margin-right: 32px;
        }
        span {
          font-size: 36px;
          font-weight: 600;
          color: #333333;
          line-height: 36px;
        }
      }
      &__content {
        background: #fafafa;
        // position: absolute;
        border-radius: 16px;
        backdrop-filter: blur(10px);
        font-family: PingFangSC-Regular, PingFang SC;
        padding: 24px;
        margin-top: 16px;
        &__top {
          font-size: 30px;
          color: #333333;
          line-height: 30px;
        }
        &__bottom {
          color: #666666;
          line-height: 24px;
          font-size: 24px;
          margin-top: 16px;
        }
      }
      &__total {
        text-align: right;
        font-size: 30px;
        color: #333333;
        line-height: 30px;
        margin-top: 32px;
        font-weight: 400;
        &__name {
          color: #999999;
        }
      }
    }
    &.warning {
      .order-item__main {
        border-radius: 16px 16px 0px 0px;
      }
    }
    &__pay {
      // position: absolute;
      background: #fff5ee;
      border-radius: 0px 0px 16px 16px;
      padding: 18px 32px;
      box-sizing: border-box;
      font-family: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif";
      &__left {
        display: flex;
        align-items: center;
        font-size: 30px;
        color: #333333;
        line-height: 30px;
        img {
          width: 84px;
          height: 84px;
          margin-right: 18px;
        }
        &__name {
          color: #666666;
          margin-right: 8px;
        }
        /deep/.van-count-down {
          font-size: 0.4rem;
        }
      }
      &__btn {
        background: #fa6400;
        border-radius: 32px;
        font-size: 32px;
        color: #ffffff;
        line-height: 32px;
        padding: 16px 31px;
      }
    }
  }
  .default {
    font-family: PingFangSC-Regular, PingFang SC;
    &__img {
      width: 364px;
      height: 306px;
      display: block;
      margin: 0 auto;
      margin-top: 200px;
    }
    &__text {
      margin-top: 48px;
      font-size: 24px;
      color: #999;
      text-align: center;
    }
  }
  .popup-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .noticePopup-box {
    padding: 32px 36px 36px 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .notice-tit {
      text-align: center;
    }
    .noticePopup-content {
      .icon-index {
        display: inline-block;
        width: 40px;
        height: 40px;
        color: #5a4444;
        background-color: #ffded9;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        margin-right: 16px;
      }
      .notice-text {
        margin-top: 32px;
        display: flex;
        align-items: center;
        .low-level {
          color: #cccccc;
        }
        .notice-text-title {
          width: 146px;
          line-height: 40px;
        }
        .notice-text-details {
          flex: 1;
          line-height: 40px;
        }
      }
      .start {
        align-items: flex-start;
      }
    }
    .notice-btn {
      width: 100%;
      height: 98px;
      background-color: #ff0904;
      border-radius: 98px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .highlight {
    color: #fa6400;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
